"use client";

import useAuth from "@/hooks/useAuth";
import Button from "@/components/Button";
import TextField from "@/components/TextField";
import styles from "./Login.module.scss";

// import react hook form
// import yup
import { useForm, SubmitHandler } from "react-hook-form";
import Link from "next/link";

interface FormProps {
  email: string;
  password: string;
}

interface Props {
  back?: boolean;
}

export default function Login(props: Props) {
  const { back = false } = props;
  const { handleLogin, loading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormProps>();

  const submitForm: SubmitHandler<FormProps> = async (data) => {
    await handleLogin({
      email: data.email,
      password: data.password,
      back,
    });
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit((data: any) => submitForm(data))}
    >
      <div>
        <TextField
          label="Seu e-mail"
          type="text"
          {...register("email", { required: true })}
          error={errors.email && "Este campo é obrigatório"}
        />
      </div>
      <div>
        <TextField
          label="Sua senha"
          type="password"
          {...register("password", { required: true })}
          error={errors.password && "Este campo é obrigatório"}
        />
      </div>
      <div className={styles.form__buttons}>
        <Button
          onClick={
            // submit form
            handleSubmit((data: any) => submitForm(data))
          }
          title="Entrar"
          loading={loading}
        />
      </div>
      <div className={styles.form__links}>
        <Link prefetch={false} href="/cadastro">
          Cadastre-se
        </Link>{" "}
        ou{" "}
        <Link prefetch={false} href="/recuperar-senha">
          Recupere sua senha
        </Link>
      </div>
    </form>
  );
}
